import { render, staticRenderFns } from "./LeoTable.vue?vue&type=template&id=3fc0ff20"
import script from "./LeoTable.vue?vue&type=script&lang=js"
export * from "./LeoTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {At: require('/opt/build/repo/components/At.js').default})
