
import { updateFvapLeosWithButter } from '~/utils/butterUtils'
import { STATE_ISO_CODE, FAKE_STATES } from '~/utils/VFAParameters'
import download from 'downloadjs'
import axios from 'axios'

export default {
  name: 'LEO-Table',
  data () {
    return {
      isActive: false,
      stateiso: "",
      tableHeader: { 
        "i": "FVAP ID (i)",
        "n": "NAME (n)",
        "f": "FAX (f)",
        "p": "TELEPHONE (p)",
        "e": "EMAIL (e)",
        "d": "FVAP DATE-STAMP (d)",
        "a1": "ADDRESS 1 (a1)",
        "a2": "ADDRESS 2 (a2)",
        "a3": "ADDRESS 3 (a3)",
        "c": "CITY (c)", /* name of city, town, etc. */
        "s": "STATE (s)", /* state ISO code */
        "z": "ZIP (z)",
        "j": "JURISDICTION (j)",
        "t": "Type of JURISDICTION (t)", /* e.g. county */
        "fpcaOffice": "Accepts FPCA",
        "fwabOffice":  "Accepts FWAB",
        "infoConfirmed": "Information Confirmed?",
        "verificationSite": "Site used to verify data",
        "changesNeeded": "Changes needed?",
      },
    }
  },
  props: [ 'butterLeos',
           'dict' ],
  methods: {
    async createLEOTable() {
      this.isActive = true

      const delimiter = "\t" // LibreOffice doesn't play nice with fields that (1) contain a comma and (2) use a comma as a field delimiter
      const newline = "\n"
      const header = this.tableHeader.s + delimiter
                   + this.tableHeader.n + delimiter
                   + this.tableHeader.j + delimiter
                   + this.tableHeader.t + delimiter
                   + this.tableHeader.a1 + delimiter
                   + this.tableHeader.a2 + delimiter
                   + this.tableHeader.a3 + delimiter
                   + this.tableHeader.c + delimiter
                   + this.tableHeader.z + delimiter
                   + this.tableHeader.e + delimiter
                   + this.tableHeader.p + delimiter
                   + this.tableHeader.f + delimiter
                   + this.tableHeader.fpcaOffice + delimiter
                   + this.tableHeader.fwabOffice + delimiter
                   + this.tableHeader.d + delimiter
                   + this.tableHeader.i + delimiter
                   + this.tableHeader.infoConfirmed + delimiter
                   + this.tableHeader.verificationSite + delimiter
                   + this.tableHeader.changesNeeded

      let csv = header + newline

      /** sort the ISO codes because the sort order in STATE_ISO_CODE is based on the full name of the state */
      const STATES = STATE_ISO_CODE.filter(s=>!FAKE_STATES.includes(s)).sort((a,b)=>{return (a<b)?-1:1})
      const STATES_LENGTH = STATES.length
      for (let ii=0; ii<STATES_LENGTH; ii++) {
        this.stateiso = STATES[ii]
        let leos = await this.getRawFVAPLeo(this.stateiso)

        leos.forEach(x=>csv +=      x.s
                + delimiter +       x.n.replaceAll("\n", " -newline- ")
                + delimiter +       x.j
                + delimiter +       x.t
                + delimiter +       x.a1.replaceAll("\n", " -newline- ")
                + delimiter +       x.a2.replaceAll("\n", " -newline- ")
                + delimiter +       x.a3.replaceAll("\n", " -newline- ")
                + delimiter +       x.c.replaceAll("\n", " -newline- ")
                + delimiter + "'" + x.z /** for ZIP codes that begin with zero */
                + delimiter +       x.e
                + delimiter +       x.p
                + delimiter +       x.f
                + delimiter +       x.fpcaOffice
                + delimiter +       x.fwabOffice
                + delimiter +       x.d
                + delimiter +       x.i
                + delimiter +       ""
                + delimiter +       ""
                + delimiter +       ""
                            +       newline
        )
      }

      this.isActive = false
      download(csv, "LEOs(TAB_separated).tsv", "text/plain")
    },
    async getRawFVAPLeo(state) {
      let fvapLeos = (await axios.get(`/leos/${state}-leos.json`)).data
      return fvapLeos.sort((a,b)=>{return (a.n<b.n)?-1:1})
    },
  },
}
